import React, {useEffect} from 'react';
import MetaTags from 'react-meta-tags';
import {Result, Spin} from "antd";

export default function FreeCase() {

  useEffect(() => {
    setTimeout(() => {
      window.location.href = 'https://csgoempire.com/r/Freecasessss'
    }, 2000)
  }, []);

  return (
    <div>
      <MetaTags>
        <title>FreeCase - CSGOEmpire.vn</title>
        <meta name="description" content="Free Case CSGOEmpire"/>
        <meta property="og:title" content="CSGOEmpire.vn"/>
      </MetaTags>
      <Result
        title="Vui lòng chờ 2 giây"
        extra={
          <Spin/>
        }
      />
    </div>
  );
}