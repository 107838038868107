import React, {useCallback, useEffect, useState} from 'react';
import {ContentState, convertToRaw, EditorState} from 'draft-js';
import {Editor} from "react-draft-wysiwyg";
import {Button} from "antd";
import {SaveOutlined} from '@ant-design/icons';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

interface ITwoModeRichTextProps {
  editable: boolean,
  content?: string,
  onSave?: (content: string) => void,
}

export default function TwoModeRichText(props: ITwoModeRichTextProps) {
  const {onSave, editable, content} = props;
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (content) {
      const contentBlock = htmlToDraft(content);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  }, [content]);

  const onClickSave = useCallback(() => {
    if (onSave) {
      const contentAsHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      onSave(contentAsHtml);
    }
  }, [editorState, onSave]);

  return (
    <div>
      <Editor
        toolbarClassName="demo-toolbar-custom"
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor-custom"
        readOnly={!editable}
        wrapperStyle={{border: editable ? 'solid 1px' : ''}}
        editorState={editorState}
        onEditorStateChange={state => setEditorState(state)}
        toolbarHidden={!editable}
        toolbarCustomButtons={[
          <div>{editable && <Button icon={<SaveOutlined />} onClick={onClickSave}/>}</div>
        ]}
      >
      </Editor>
    </div>
  )
}