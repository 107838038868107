import React, {useCallback, useState} from 'react';
import {Image, Layout, Menu, Modal} from 'antd';
import styled from "styled-components";
import {LoginForm} from "../../modules/auth/LoginForm";
import {Link} from 'react-router-dom';
import useAuth from "../../features/auth/AuthProvider";

const {Header, Content, Footer} = Layout;

interface IAppLayoutProps {
  children: React.ReactNode;
}

const Logo = styled.div`
  float: left;
  width: 200px;
`;
const RightMenuItem = styled(Menu.Item)`
  float: right;
`

export default function AppLayout(props: IAppLayoutProps) {
  const [showLoginForm, setShowLoginForm] = useState(false);
  const {isAuthenticated, doLogout, userInfo} = useAuth();

  const onClickLogin = () => {
    setShowLoginForm(true);
  }
  const onClickLogout = async () => {
    await doLogout();
  }

  const onHideLogin = useCallback(() => {
    setShowLoginForm(false);
  },[]);

  return (
    <Layout>
      <Header className={"header"}>
        <Logo>
          <Link to={'/'}>
          <Image src={'/logohome.png'} preview={false}></Image>
          </Link>
        </Logo>
        <Menu theme="dark" mode="horizontal">
          <Menu.Item>
            <Link to={'/'}>Trang Chủ</Link>
          </Menu.Item>
          <Menu.Item>
            <Link to={{ pathname: "https://ezvalue.vn" }} target="_blank">Value Etopfun</Link>
          </Menu.Item>
          {userInfo.role === "SUPER_ADMIN" &&
          <Menu.Item>
            <Link to={'/admin'}>Admin</Link>
          </Menu.Item>}
          {!isAuthenticated && <RightMenuItem onClick={onClickLogin}>Đăng nhập</RightMenuItem>}
          {isAuthenticated && <RightMenuItem onClick={onClickLogout}>Đăng xuất</RightMenuItem>}
        </Menu>
      </Header>
      <Content style={{padding: '0 50px'}}>
        {props.children}
      </Content>
      <Footer style={{textAlign: 'center'}}>EZ ©2021 Developed by Nothing</Footer>
      <Modal
        visible={showLoginForm}
        title="Đăng nhập"
        footer={null}
        onCancel={onHideLogin}
      >
        <LoginForm
          afterSubmit={onHideLogin}
        />
      </Modal>
    </Layout>
  );
}