import {request} from "../utils/http";
import jwtDecode from "jwt-decode";

const AUTH_TOKEN = 'AUTH_TOKEN';

export interface ILoginInfo {
  username: string;
  password: string;
  rememberMe?: boolean;
}

export interface ITokenInfo {
  accessToken: string;
  expiresIn: number;
}

export function login(loginInfo: ILoginInfo): Promise<ITokenInfo> {
  return request<ITokenInfo>({
    url: '/auth/login',
    method: 'POST',
    authRequired: false,
    data: loginInfo
  });
}

export function logout() {
  return request<void>({
    url: '/auth/logout',
    method: 'POST',
    authRequired: true,
  });
}

export function getAuthInfoFromCache(): string {
  const token = getAuthToken();
  return getAuthInfo(token);
}

function getAuthInfo(token: string): string {
  let jwt: any
  try {
    jwt = jwtDecode(token);
  } catch (e) {
    jwt = {};
  }

  return jwt.sub;
}

export function getAuthToken() {
  if (document.cookie.length > 0) {
    let start = document.cookie.indexOf(AUTH_TOKEN + '=');
    if (start !== -1) {
      start = start + AUTH_TOKEN.length + 1;
      let end = document.cookie.indexOf(';', start);
      if (end === -1) {
        end = document.cookie.length;
      }
      return unescape(document.cookie.substring(start, end));
    }
  }
  return '';
}