import {Button, Result } from "antd";
import React from "react";

export default function Page404() {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Xin lỗi, trang bạn muốn truy cập không tồn tại."
      extra={<Button type="primary" href={'/'}>Trang chủ</Button>}
    />
  );
}