import _, {round} from "lodash";
import {Button, Card, Col, Form, Input, InputNumber, message, Row, Select, Typography} from "antd";
import {useHistory} from "react-router-dom";
import styled from "styled-components";
import {getTipVnd, IRequestTipInfo, requestTip} from "../../services/home";
import {useCallback, useEffect, useMemo, useState} from "react";
import InfoPanel from "./InfoPanel";
import {SwapOutlined} from '@ant-design/icons';
import {calculateFee, calculateVndToTip} from "../../utils";
import useHomeInfo from "../../features/auth/HomeInfoProvider";

const {Option} = Select;

const layout = {
    labelCol: {span: 7},
    wrapperCol: {span: 13},
};
const tailLayout = {
    wrapperCol: {offset: 7, span: 13},
};

const Container = styled.div`
    padding: 20px;
    margin-left: 10%;
    margin-right: 10%;

    @media only screen and (max-width: 900px) {
        max-width: 100%;
        padding: 0;
        margin-left: 0;
        margin-right: 0;
    }
`;

interface IRequestTipForm extends IRequestTipInfo {
    tipInVnd: number
}

export default function TipRequest() {
    const [form] = Form.useForm<IRequestTipForm>();
    const [bankList, setBankList] = useState<BankInfo[]>([]);
    const [fee, setFee] = useState(0);
    const {homeInfo} = useHomeInfo();
    let history = useHistory();

    const onSubmitForm = (form: IRequestTipForm) => {
        requestTip(form).then(result => {
            history.push(`/tip-request-result?tipId=${result.tipId}&depId=${result.depId}`);
        }).catch(async reason => {
            await message.error('Đã có lỗi xảy ra! ' + reason);
        });
    }
    
    const setDefaultBank = useCallback((bankCode: string) => {
        form.setFieldsValue({
            bank: bankCode
        });
    }, [form]);

    useEffect(() => {
        (async () => {
            const autoBanks = _.filter(homeInfo.banks, {auto: true});
            const sortedBankList = _.sortBy(autoBanks, ['priority']);
            setBankList(sortedBankList);
            if (sortedBankList && sortedBankList[0]) {
                setDefaultBank(sortedBankList[0].code);
            }
        })();
    }, [homeInfo.banks, setDefaultBank]);

    const onChangedForm = useCallback((changedValues: any, values: IRequestTipForm) => {
        (async () => {
            const {amount, tipInVnd} = changedValues;
            let currentTipInVnd = tipInVnd ? tipInVnd : values.tipInVnd;
            if (amount !== undefined) {
                if (amount > 0) {
                    const roundedAmount = round(amount, 2);
                    const vnd = await getTipVnd(roundedAmount);
                    currentTipInVnd = vnd;
                    form.setFieldsValue({tipInVnd: vnd, amount: roundedAmount});
                }
            } else if (tipInVnd !== undefined) {
                const usd = calculateVndToTip(tipInVnd, homeInfo.csgoEmpireRate);
                form.setFieldsValue({amount: usd});
            }

            const selectedBank = _.find(bankList, {code: values.bank});
            const feeVnd = calculateFee(currentTipInVnd, selectedBank?.fee);
            setFee(feeVnd);
        })();
    }, [bankList, form, homeInfo.csgoEmpireRate]);


    return (
        <Container>
            <InfoPanel forCard={false}/>
            <Card title={`Mua coin CSGO Empire (có sẵn: ${homeInfo.balance.toLocaleString('vn')})`}>
                <Form
                    {...layout}
                    onFinish={onSubmitForm}
                    form={form}
                    onValuesChange={onChangedForm}
                >
                    <Form.Item
                        label="Steam ID (id64)"
                        name="steamId"
                        rules={[{required: true, message: 'Vui lòng nhập Steam ID của bạn!'},
                            {len: 17, message: 'Steam ID phải có 17 kí tự'},
                            {pattern: RegExp('^[0-9]*$'), message: 'Steam ID chỉ bao gồm chữ số'}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="Số lượng coin"
                        required={true}
                    >
                        <Row>
                            <Col>
                                <Form.Item
                                    name="amount"
                                    rules={[
                                        {required: true, message: 'Vui lòng nhập số lượng Tip bạn muốn nạp!'},
                                    ]}
                                    noStyle={true}
                                >
                                    <InputNumber
                                        min={0.1}
                                        max={homeInfo.balance}
                                        step={0.01}
                                        placeholder={'Tip'}
                                        style={{width: 100}}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <SwapOutlined style={{margin: 4}}/>
                                <Form.Item
                                    name="tipInVnd"
                                    rules={[{required: true, message: 'Vui lòng nhập số tiền bạn muốn nạp!'}]}
                                    noStyle={true}
                                >
                                    <InputNumber
                                        min={1000}
                                        step={1000}
                                        max={1000000000}
                                        style={{width: 200}}
                                        placeholder={'VND'}
                                    />
                                </Form.Item>
                            </Col>
                            {fee > 0 && <Col>
                              <Typography style={{padding: '4px', fontWeight: 'bold'}}> +
                                Phí: {fee.toLocaleString('vn')}</Typography>
                            </Col>}
                        </Row>
                    </Form.Item>
                    <Form.Item
                        label="Phương thức thanh toán"
                        name={'bank'}
                        rules={[{required: true, message: 'Vui lòng chọn phương thức thanh toán thích hợp!'}]}
                    >
                        <Select>
                            {_.map(bankList, function (bank: BankInfo) {
                                return (<Option key={bank.code} value={bank.code}>{bank.name}</Option>)
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                            Mua
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </Container>
    );
}
