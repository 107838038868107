import React from 'react';
import TipInfoTable from "./TipInfoTable";
import {PageHeader} from "@ant-design/pro-layout";
import styled from "styled-components";

const Container = styled.div`
  margin: 8px;
  width: 100%;
`;
export default function TipManager() {

  return (
    <Container>
      <PageHeader title={'Danh sách mua tip CSGO Empire'}/>
      <TipInfoTable/>
    </Container>);
}