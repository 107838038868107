import React from 'react'
import {Layout, Menu} from "antd";
import {SettingOutlined, SolutionOutlined} from '@ant-design/icons';
import {MenuInfo} from "rc-menu/lib/interface";
import {Link, Route, Switch} from "react-router-dom";
import AppearanceSetting from "./AppearanceSetting";
import TipManager from "./TipManager";
import Revenue from "./Revenue";

const {Sider, Content} = Layout;
const {SubMenu} = Menu;

export default function Admin() {

  const handleClick = (info: MenuInfo) => {
    console.log('click ', info);
  };

  return (
    <Layout>
      <Sider>
        <Menu
          mode={"inline"}
          onClick={handleClick}
        >
          <SubMenu key="setting" icon={<SettingOutlined/>} title="Cài đặt">
            <Menu.Item key="appearance">
              <Link to={'/admin/appearance'}>Giao diện</Link>
            </Menu.Item>
            <Menu.Item key="advance">
              <Link to={'/admin/advance'}>Nâng cao</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="manager" icon={<SolutionOutlined />} title="Quản lý">
            <Menu.Item key="tip-manager">
              <Link to={'/admin/tip-manager'}>Tip</Link>
            </Menu.Item>
            <Menu.Item key="revenue">
              <Link to={'/admin/revenue'}>Doanh Thu</Link>
            </Menu.Item>
          </SubMenu>
        </Menu>
      </Sider>
      <Content>
        <Switch>
          <Route path={"/admin/appearance"}>
            <AppearanceSetting/>
          </Route>
          <Route path={"/admin/tip-manager"}>
            <TipManager/>
          </Route>
          <Route path={"/admin/revenue"}>
            <Revenue/>
          </Route>
          <Route path={"/admin/*"}>
            <div>Chào mừng admin</div>
          </Route>
        </Switch>
      </Content>
    </Layout>)
}