import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import {getAuthInfoFromCache, getAuthToken, ILoginInfo, login, logout} from "../../services/auth";
import {message, Modal} from "antd";
import {getUserInfo, IUserInfo} from "../../services/user";

interface IAuthContext {
  isAuthenticated: boolean;
  token: string;
  userInfo: IUserInfo;
  doLogin: (loginInfo: ILoginInfo) => void;
  doLogout: () => void;
}

const DEFAULT_USER: IUserInfo = {
  role: "ANONYMOUS",
  username: 'Khách'
}

const AuthContext = createContext<IAuthContext>({
  isAuthenticated: false,
  token: '',
  userInfo: DEFAULT_USER,
  doLogin: () => {
  },
  doLogout: () => {
  },
});

type AuthProviderProps = {
  children: React.ReactNode
}

export function AuthProvider({children}: AuthProviderProps) {
  const [userInfo, setUserInfo] = useState<IUserInfo>(DEFAULT_USER);
  const [isAuthenticated, setAuthenticated] = useState(false);

  const doLogin = (loginInfo: ILoginInfo) => {
    if (isAuthenticated) {
      Modal.error({
        title: 'Hmm...',
        content: 'Bạn đã đăng nhập rồi!',
      });
      return;
    }

    login(loginInfo)
      .then(async () => {
        const user = await fetchUserInfo();
        Modal.success({
          title: 'Đăng nhập thành công!',
          content: `Chào mừng bạn ${user.username}`,
        });
        setAuthenticated(true);
      })
      .catch(async (error) => {
        await message.error('Đăng nhập không thành công!');
        setUserInfo(DEFAULT_USER);
        setAuthenticated(false);
      });
  };

  const doLogout = () => {
    logout().then(() => {
      setUserInfo(DEFAULT_USER);
      setAuthenticated(false);
    });
  };

  const fetchUserInfo = useCallback(async () => {
    try {
      const user = await getUserInfo();
      setUserInfo(user);
      return user;
    } catch (e) {
      setUserInfo(DEFAULT_USER);
      return DEFAULT_USER;
    }
  }, []);

  useEffect(() => {
    const hasAuthFromCache = getAuthInfoFromCache();
    setAuthenticated(!!hasAuthFromCache);
  }, []);

  useEffect(() => {
    (async () => {
        if (isAuthenticated) {
          await fetchUserInfo();
        } else {
          setUserInfo(DEFAULT_USER);
        }
      }
    )()
  }, [fetchUserInfo, isAuthenticated]);

  return (
    <AuthContext.Provider
      value={{isAuthenticated: isAuthenticated, token: getAuthToken(), doLogin, doLogout, userInfo}}>
      {children}
    </AuthContext.Provider>
  );
}

export default function useAuth() {
  const {isAuthenticated, doLogin, doLogout, userInfo, token} = useContext(AuthContext);

  if (doLogin === undefined || doLogout === undefined || isAuthenticated === undefined || token === undefined || userInfo === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return {isAuthenticated, doLogin, doLogout, userInfo, token}
}