import {Alert, Button, Descriptions, Divider, Result, Typography} from "antd";
import _ from "lodash";
import React, {useEffect, useMemo, useState} from "react";
import {getDepRequestInfo, getTipRequestInfo, IDepRequestResult} from "../../services/home";
import Page404 from "../../commons/ui/Page404";
import {useLocation} from "react-router-dom";
import styled from "styled-components";
import {HomeFilled} from '@ant-design/icons';
import {vietqr} from "../../utils/vietqr";
import {QrCodeImage} from "../../commons/components/QrCodeImage";
const {Text} = Typography;

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Container = styled.div`
  margin: auto;
  width: 100%;
`;

const MessageContainer = styled.div`
  margin: auto;

  @media only screen and (max-width: 900px) {
    max-width: 100%;
  }
`;

export default function RequestTipResultMessage() {

    let query = useQuery();

    const tipId = query.get("tipId");
    const depId = query.get("depId");

    const [infoString, setInfoString] = useState('');
    const [bankInfo, setBankInfo] = useState<BankInfo>();
    const [depRequest, setDepRequest] = useState<IDepRequestResult>();

    const content = useMemo((): string => {
        return depRequest ? `TD${depRequest.id} CK` : '';
    }, [depRequest]);

    const qrCodeValue = useMemo(() => {
        if (!depRequest || !bankInfo) {
            return '';
        }
        return bankInfo.code === "MOMO"
            ? vietqr.generateMomoQr(bankInfo.accountNumber, bankInfo.accountName, depRequest.actualAmount, content)
            : vietqr.generateUrl(bankInfo.code, bankInfo.accountNumber, depRequest.actualAmount, content, bankInfo.accountName);

    }, [bankInfo, content, depRequest]);

    useEffect(() => {
        (async () => {
            if (_.isNil(tipId) || _.isNil(depId)) {
                setInfoString('');
            } else {
                const tipRequest = await getTipRequestInfo(tipId);
                const depRequestResult = await getDepRequestInfo(depId);
                const info = `Yêu cầu mua ${tipRequest.amount.toLocaleString('vn')} value gửi đến tài khoản có Steam ID ${tipRequest.steamId} thành công`;

                setDepRequest(depRequestResult);
                setBankInfo(depRequestResult.bank);
                setInfoString(info);
            }
        })();
    }, [depId, tipId]);

    if (_.isNil(tipId) || _.isNil(depId) || _.isEmpty(infoString)) {
        return (
            <Page404/>
        );
    } else {
        return (
            <Container>
                <Result
                    style={{backgroundColor: 'white'}}
                    status="success"
                    title={infoString}
                    extra={[
                        <MessageContainer>
                            <Alert
                                type={"info"}
                                message={"Để hoàn tất mua hàng, bạn vui lòng chuyển khoản với thông tin như sau:"}
                                description={
                                    <div>
                                        {qrCodeValue &&
                                          <div>
                                            <h3> Quét mã QR để thanh toán</h3>
                                            <QrCodeImage value={qrCodeValue} isUrl={bankInfo?.code !== "MOMO"}/>
                                            <Divider>Hoặc</Divider>
                                          </div>
                                        }
                                        {bankInfo && depRequest &&
                                          <Descriptions
                                            title={'Thông tin chuyển khoản'}
                                            column={1}
                                          >
                                            <Descriptions.Item contentStyle={{fontWeight: 'bold'}}
                                                               label={'Ngân hàng'}>{bankInfo.name}</Descriptions.Item>
                                            <Descriptions.Item contentStyle={{fontWeight: 'bold'}} label={'Số tài khoản'}>
                                                <Text copyable={true}>{bankInfo.accountNumber}</Text>
                                            </Descriptions.Item>
                                            <Descriptions.Item contentStyle={{fontWeight: 'bold'}}
                                                               label={'Tên tài khoản'}>{bankInfo.accountName}</Descriptions.Item>
                                            <Descriptions.Item
                                              contentStyle={{fontWeight: 'bold'}}
                                              label={'Số tiền'}
                                            >
                                                {depRequest.actualAmount.toLocaleString('vn')}đ
                                                {depRequest.actualAmount !== depRequest.amount
                                                    ? ` (${depRequest.amount.toLocaleString('vn')} + Phí: ${(depRequest.actualAmount - depRequest.amount).toLocaleString('vn')})`
                                                    : ''}
                                            </Descriptions.Item>
                                            <Descriptions.Item contentStyle={{fontWeight: 'bold'}} label={'Nội dung'}>
                                                <Text copyable={true}>{content}</Text>
                                            </Descriptions.Item>
                                          </Descriptions>}
                                    </div>
                                }/>
                        </MessageContainer>,
                        <Button type="primary" href={'/'} style={{margin: 8}} icon={<HomeFilled/>}>
                            Quay về trang chủ
                        </Button>,
                    ]}
                >
                </Result>
            </Container>
        );
    }
}
