import {request} from "../utils/http";

export interface IUserInfo {
  username: string;
  role: UserRole;
  email?: string;
  steamId?: string;
  fullName?: string;
}

export function getUserInfo() {
  return request<IUserInfo>({
    url: '/user',
    method: 'GET',
    authRequired: true,
  });
}