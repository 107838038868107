import moment from "moment-timezone";

export const roundToVnd = (value: number) => {
    return Math.ceil(value / 1000) * 1000;
}

export const calculateTipVnd = (tipInUsd: number, rate: number) => {
    const tipInVnd = tipInUsd * rate * 1000;
    return roundToVnd(tipInVnd);
}

export const calculateVndToTip = (vnd: number, rate: number) => {
    const usd = vnd / (rate * 1000);
    return Math.floor(usd * 100) / 100;
}

export const calculateFee = (tipInVnd: number, fee?: FeeInfo) => {
    if (!fee) {
        return 0;
    }
    const feeRate = fee.rate ? fee.rate : 0;
    const feeBasic = fee.basicFee ? fee.basicFee : 0;
    const totalFee = tipInVnd * feeRate + feeBasic;

    return roundToVnd(totalFee);
}

export const formatDatetime = (dateTime?: moment.Moment) => {
    return dateTime ?
        moment.tz(dateTime, 'UTC').tz('Asia/Ho_Chi_Minh').format('YYYY-MM-DD HH:mm:ss')
        : '';
}

export const formatNumber = (value?: number) => {
    if (!value) {
        return '0';
    }
    const roundedNumber = Math.round(value * 100) / 100;
    return roundedNumber.toLocaleString();
}

export const formatVnd = (vnd?:number) => {
    if (!vnd) {
        return '0';
    }
    return roundToVnd(vnd).toLocaleString();
}