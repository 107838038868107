import React from 'react'
import {Redirect, Route, RouteProps} from "react-router-dom";
import _ from "lodash";
import useAuth from "../../features/auth/AuthProvider";

interface IProtectedRouteProps extends RouteProps {
  accessibleRoles: UserRole[];
  redirectTo?: string;
}

export default function ProtectedRoute(props: IProtectedRouteProps) {
  const {accessibleRoles, redirectTo, children, ...rest} = props;
  const {isAuthenticated, userInfo} = useAuth();

  const hasPermission = isAuthenticated && userInfo && _.includes(accessibleRoles, userInfo.role);

  if (!hasPermission) {
    return (
      <Redirect to={redirectTo ? redirectTo : '/'}/>
    )
  }

  return (
    <Route {...rest}>
      {children}
    </Route>
  );
}