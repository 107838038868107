import React, {createContext, useContext, useEffect, useState} from 'react';
import {getHomeInfo} from "../../services/home";

interface IHomeInfoContext {
  homeInfo: HomeInfo;
}

const DEFAULT_HOME_INFO: HomeInfo = {
  banks: [],
  csgoEmpireRate: 15.2,
  buyingRate: 14.7,
  webIntro: '',
  webIntroForCard: '',
  balance: 0,
  cardInfoList: [],
}

const HomeInfoContext = createContext<IHomeInfoContext>({homeInfo: DEFAULT_HOME_INFO});

type HomeProviderProps = {
  children: React.ReactNode
}

export function HomeInfoProvider({children}: HomeProviderProps) {
  const [homeInfo, setHomeInfo] = useState<HomeInfo>(DEFAULT_HOME_INFO);

  useEffect(() => {
    (async () => {
      const home = await getHomeInfo();
      setHomeInfo(home);
    })();
  }, []);

  return (
    <HomeInfoContext.Provider value={{homeInfo}}>
      {homeInfo && children}
    </HomeInfoContext.Provider>
  );
}

export default function useHomeInfo() {
  const {homeInfo} = useContext(HomeInfoContext);

  if (homeInfo === undefined) {
    throw new Error('Must have home info to use this app!');
  }

  return {homeInfo}
}
