import React from 'react';
import {Route, Switch,} from "react-router-dom";
import '../App.css';
import TipRequest from '../modules/tip/TipRequest';
import Page404 from "../commons/ui/Page404";
import RequestTipResultMessage from "../modules/tip/RequestTipResultMessage";
import AppLayout from "./AppLayout";
import ProtectedRoute from "../commons/components/ProtectedRoute";
import Admin from "../modules/admin/Admin";
import FreeCase from "../modules/FreeCase";
import CardTipResultMessage from "../modules/tip/card/CardTipResultMessage";
import {FloatButton} from "antd";
import {CustomerServiceOutlined} from "@ant-design/icons";

function App() {
  return (
    <AppLayout>
      <Switch>
        <Route exact path="/">
          <TipRequest/>
        </Route>
        <Route path="/tip-request-result">
          <RequestTipResultMessage/>
        </Route>
        <Route path="/card-tip-request-result">
          <CardTipResultMessage/>
        </Route>
        <Route exact={true} path="/items" render={() => (window.location.href = 'https://ezvalue.vn/exchange-coin')}/>
        <Route exact={true} path="/etopfun" render={() => (window.location.href = 'https://ezvalue.vn/pick')}/>
        <Route exact={true} path="/freecase">
          <FreeCase/>
        </Route>
        <ProtectedRoute path={"/admin"} accessibleRoles={["SUPER_ADMIN"]}>
          <Admin/>
        </ProtectedRoute>
        <Route path="*">
          <Page404/>
        </Route>
      </Switch>

      <FloatButton
          type={"primary"}
          icon={<CustomerServiceOutlined />}
          href={"https://m.me/kuhau2103"}
          target={"_blank"}
      />
    </AppLayout>
  );
}

export default App;
