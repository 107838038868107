import React from 'react';
import {PageHeader} from "@ant-design/pro-layout";

export default function AppearanceSetting() {

  return (
    <div>
      <PageHeader title={'Cài đặt giao diện'}/>
    </div>
  )
}