import React from "react";
import {Button, Checkbox, Form, Input} from "antd";
import useAuth from "../../features/auth/AuthProvider";

const layout = {
  labelCol: {span: 8},
  wrapperCol: {span: 16},
};
const tailLayout = {
  wrapperCol: {offset: 8, span: 16},
};

export interface ILoginFormProps {
  afterSubmit?: () => void,
}

export function LoginForm(props: ILoginFormProps) {
  const {afterSubmit} = props;
  const {doLogin} = useAuth();

  const onFinish = async (values: any) => {
    await doLogin({
      username: values.username,
      password: values.password,
      rememberMe: values.remember
    });
    if (afterSubmit) {
      afterSubmit();
    }
  };

  const onFinishFailed = (errorInfo: any) => {
  };

  return (
    <Form
      {...layout}
      name="basic"
      initialValues={{remember: true}}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Username"
        name="username"
        rules={[{required: true, message: 'Vui lòng nhập tên đăng nhập!'}]}
      >
        <Input/>
      </Form.Item>

      <Form.Item
        label="Mật khẩu"
        name="password"
        rules={[{required: true, message: 'Vui lòng nhập mật khẩu!'}]}
      >
        <Input.Password/>
      </Form.Item>

      <Form.Item {...tailLayout} name="remember" valuePropName="checked">
        <Checkbox>Remember me</Checkbox>
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Đăng nhập
        </Button>
      </Form.Item>
    </Form>
  );
}