import React, {useCallback, useEffect, useState} from "react";
import {ITipDepRestDto, listTipRequests} from "../../../services/admin";
import {Button, Card, Col, Divider, Form, Input, Row, Select, Table} from "antd";
import {ColumnsType, SorterResult} from "antd/lib/table/interface";
import moment from "moment";
import _ from "lodash";
import {PaginationProps} from "antd/lib/pagination";
import {formatDatetime} from "../../../utils";

const {Option} = Select;

interface ITipInfo {
  depId?: number,
  tipId: number,
  createdAt: moment.Moment,
  steamId: string,
  depStatus?: RequestStatus,
  tipStatus: RequestStatus,
  bank?: BankInfo,
  amount: number,
  amountInVnd?: number,
}
type TipFilterForm = TipFilterInfo & {
  type: string,
}
const mapTipInfoData = (tipDeps: ITipDepRestDto[]) => {
  return _.map(tipDeps, function (tipDep) {
    return {
      depId: tipDep.depRequest?.id,
      tipId: tipDep.tipRequest.id,
      createdAt: tipDep.tipRequest.createdAt,
      steamId: tipDep.tipRequest.steamId,
      depStatus: tipDep.depRequest?.status,
      tipStatus: tipDep.tipRequest.status,
      bank: tipDep.depRequest?.bank,
      amount: tipDep.tipRequest.amount,
      amountInVnd: tipDep.depRequest?.actualAmount,
    }
  });
}
export default function TipInfoTable() {
  const [filterForm] = Form.useForm<TipFilterForm>();
  const [tipInfos, setTipInfos] = useState<ITipInfo[]>([]);
  const [tipSearchOpts, setTipSearchOpts] = useState<TipFilterInfo>();
  const [pageRequest, setPageRequest] = useState<PageRequestInfo>({
    page: 0,
    pageSize: 10,
  });
  const [totalItems, setTotalItems] = useState(0);
  const [loadingTable, setLoadingTable] = useState(false);

  useEffect(() => {
    (async () => {
      setLoadingTable(true);
      const result = await listTipRequests(pageRequest, tipSearchOpts);
      setTipInfos(mapTipInfoData(result.content));
      setTotalItems(result.totalElements);
      setLoadingTable(false);
    })();
  }, [pageRequest, tipSearchOpts]);

  const columns: ColumnsType<ITipInfo> = [
    {
      title: 'Tip ID',
      dataIndex: 'tipId',
      key: 'id',
      sorter: true,
    },
    {
      title: 'Mã nạp tiền',
      dataIndex: 'depId',
      key: 'depRequest.id',
      sorter: true,
    },
    {
      key: 'steamId',
      title: 'Steam ID',
      dataIndex: 'steamId',
      sorter: true
    },
    {
      key: 'createdAt',
      title: 'Thời gian tạo',
      dataIndex: 'createdAt',
      render: createdAt => formatDatetime(createdAt),
      defaultSortOrder: "descend",
      sorter: true
    },
    {
      key: 'depRequest.bank',
      title: 'Ngân hàng',
      dataIndex: 'bank',
      render: (bank: BankInfo) => bank ? bank.name : '',
      sorter: true,
    },
    {
      key: 'amount',
      title: 'Coin',
      dataIndex: 'amount',
      sorter: true,
    },
    {
      key: 'depRequest.amount',
      title: 'Số tiền VND',
      dataIndex: 'amountInVnd',
      render: vnd => vnd ? vnd.toLocaleString('vn') : '',
      sorter: true
    },
    {
      key: 'depRequest.status',
      title: 'Trạng thái nạp tiền',
      dataIndex: 'depStatus',
      sorter: true
    },
    {
      key: 'status',
      title: 'Trạng thái tip',
      dataIndex: 'tipStatus',
      sorter: true
    }
  ];

  const handleChangeTable = useCallback((pagination: PaginationProps, filters: any, sorter: SorterResult<ITipInfo> | SorterResult<ITipInfo>[]) => {
    const updatingPageRequest: PageRequestInfo = {
      page: pagination.current ? pagination.current - 1 : 0,
      pageSize: pagination.pageSize ? pagination.pageSize : 10,
    };

    let sortOptions: SorterResult<ITipInfo> | undefined;
    if (!Array.isArray(sorter)) {
      sortOptions = sorter;
    } else if (sorter.length > 0) {
      sortOptions = sorter[0];
    }
    if (sortOptions) {
      let direction: Direction | undefined;
      if (sortOptions.order === "ascend") {
        direction = "ASC";
      } else if (sortOptions.order === "descend") {
        direction = "DESC"
      }
      updatingPageRequest.sortDirection = direction;
      updatingPageRequest.sortProp = sortOptions.columnKey ? sortOptions.columnKey.toString() : undefined
    }

    setPageRequest(updatingPageRequest);
  }, []);

  const onSubmitSearch = useCallback((form: TipFilterForm) => {
    let hasDep = undefined;
    if (form.type === 'hasDep') {
      hasDep = true;
    } else if (form.type === 'noDep') {
      hasDep = false;
    }
    setTipSearchOpts({
      ...form,
      hasDep
    });
  }, []);

  return (
    <div>
      <Card title={'Tìm kiếm'}>
        <Form
          form={filterForm}
          onFinish={onSubmitSearch}
          layout={'inline'}
        >
          <Row gutter={10}>
            <Col>
              <Form.Item
                label="Mã nạp tiền"
                name="depId"
              >
                <Input/>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label="Steam ID"
                name="steamId"
              >
                <Input/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col>
              <Form.Item
                label="Loại"
                name={'type'}
              >
                <Select style={{width: '150px'}}>
                  <Option key={"ALL"} value={"ALL"}>Tất cả</Option>
                  <Option key={"hasDep"} value={"hasDep"}>Khách nạp tiền</Option>
                  <Option key={"noDep"} value={"noDep"}>Admin tip</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label="Trạng thái nạp tiền"
                name={'depStatus'}
              >
                <Select style={{width: '150px'}}>
                  <Option key={"PENDING"} value={"PENDING"}>Đang chờ</Option>
                  <Option key={"SUCCESS"} value={"SUCCESS"}>Thành công</Option>
                  <Option key={"FAILED"} value={"FAILED"}>Lỗi</Option>
                  <Option key={"CANCEL"} value={"CANCEL"}>Hủy</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label="Trạng thái tip"
                name={'status'}
              >
                <Select style={{width: '150px'}}>
                  <Option key={"PENDING"} value={"PENDING"}>Đang chờ</Option>
                  <Option key={"SUCCESS"} value={"SUCCESS"}>Thành công</Option>
                  <Option key={"FAILED"} value={"FAILED"}>Lỗi</Option>
                  <Option key={"CANCEL"} value={"CANCEL"}>Hủy</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Tìm kiếm
              </Button>
            </Form.Item>
            <Button
              style={{margin: '0 8px'}}
              onClick={() => {
                filterForm.resetFields();
              }}
            >
              Clear
            </Button>
          </Row>
        </Form>
        <Divider/>
        <Table
          columns={columns}
          dataSource={tipInfos}
          pagination={{
            current: pageRequest.page + 1,
            pageSize: pageRequest.pageSize,
            total: totalItems,
          }}
          onChange={handleChangeTable}
          loading={loadingTable}
        />
      </Card>
    </div>);
}