import React, {useEffect, useState} from 'react';
import {PageHeader} from "@ant-design/pro-layout";
import styled from "styled-components";
import {getRevenue, IRevenueDto} from "../../../services/admin";
import {Card, Col, message, Row, Typography} from "antd";
import {formatNumber, formatVnd} from "../../../utils";

const {Title} = Typography;

const Container = styled.div`
    margin: 8px;
    width: 100%;
`;

const ContentContainer = styled.div`
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: 400px;
`;

export default function Revenue() {
    const [revenueInfo, setRevenueInfo] = useState<IRevenueDto>({
        dailyAmountOfOrders: 0,
        dailyRevenue: 0,
        dailyCoin: 0,
        monthlyAmountOfOrders: 0,
        monthlyRevenue: 0,
        monthlyCoin: 0
    });

    useEffect(() => {
        getRevenue()
            .then(revenue => {
                setRevenueInfo(revenue);
            })
            .catch(error => {
                message.error('Đã có lỗi xảy ra! ' + error);
            })
    }, []);

    return (
        <Container>
            <PageHeader title={'Doanh thu'}/>
            <ContentContainer>
                <div>
                    <Title level={4}>Ngày</Title>
                    <Row gutter={20}>
                        <Col lg={12} md={24}>
                            <Card title={'Tổng Tiền'}>
                                <Title level={5}>
                                    {formatVnd(revenueInfo.dailyRevenue)}
                                </Title>
                            </Card>
                        </Col>
                        <Col lg={12} md={24}>
                            <Card title={'Coin'}>
                                <Title level={5}>
                                    {formatNumber(revenueInfo.dailyCoin)}
                                </Title>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Title level={4}>Tháng</Title>
                    <Row gutter={20}>
                        <Col lg={12} md={24}>
                            <Card title={'Tổng tiền'}>
                                <Title level={5}>
                                    {formatVnd(revenueInfo.monthlyRevenue)}
                                </Title>
                            </Card>
                        </Col>
                        <Col lg={12} md={24}>
                            <Card title={'Coin'}>
                                <Title level={5}>
                                    {formatNumber(revenueInfo.monthlyCoin)}
                                </Title>
                            </Card>
                        </Col>
                    </Row>
                </div>

            </ContentContainer>
        </Container>
    );
}