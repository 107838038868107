import {Alert, Button, Descriptions, Result} from "antd";
import _ from "lodash";
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import styled from "styled-components";
import {HomeFilled} from '@ant-design/icons';
import Page404 from "../../../commons/ui/Page404";
import {getDepRequestInfo, getTipRequestInfo, IDepRequestResult} from "../../../services/home";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Container = styled.div`
  margin: auto;
  width: 100%;
`;

const MessageContainer = styled.div`
  max-width: 50%;
  margin: auto;

  @media only screen and (max-width: 900px) {
    max-width: 100%;
  }
`;

export default function CardTipResultMessage() {

  let query = useQuery();

  const tipId = query.get("tipId");
  const depId = query.get("depId");
  const cardTelco = query.get("telco");
  const cardCode = query.get("code");
  const cardSerial = query.get("serial");
  const cardValue = query.get("value");

  const [infoString, setInfoString] = useState('');
  const [depRequest, setDepRequest] = useState<IDepRequestResult>();

  useEffect(() => {
    (async () => {
      if (_.isNil(tipId) || _.isNil(depId)) {
        setInfoString('');
      } else {
        const tipRequest = await getTipRequestInfo(tipId);
        const depRequestResult = await getDepRequestInfo(depId);
        const info = `Yêu cầu mua ${tipRequest.amount.toLocaleString('vn')} value gửi đến tài khoản có Steam ID ${tipRequest.steamId} thành công`;

        setDepRequest(depRequestResult);
        setInfoString(info);
      }
    })();
  }, [depId, tipId]);

  if (_.isNil(tipId) || _.isNil(depId) || _.isEmpty(infoString)) {
    return (
      <Page404/>
    );
  } else {
    return (
      <Container>
        <Result
          style={{backgroundColor: 'white'}}
          status="success"
          title={infoString}
          extra={[
            <MessageContainer>
              <Alert
                type={"info"}
                message={"Chúng tôi đã ghi nhận thông tin mã thẻ. Tài khoản của bạn sẽ được cộng tiền sau khi mã thẻ được kiểm tra thành công!"}
                description={
                  depRequest &&
                    <Descriptions column={1}>
                      <Descriptions.Item
                          contentStyle={{fontWeight: 'bold'}}
                          label={'Thông tin thẻ'}
                      >
                        Nhà mạng: {cardTelco}. Mã thẻ: {cardCode}. Serial: {cardSerial}. Mệnh giá: {cardValue}
                      </Descriptions.Item>
                      <Descriptions.Item
                          contentStyle={{fontWeight: 'bold'}}
                          label={'Thời gian kiểm tra mã thẻ'}
                      >
                        Từ 2 đến 10 phút
                      </Descriptions.Item>
                      <Descriptions.Item
                          contentStyle={{fontWeight: 'bold'}}
                      >
                        Nếu tài khoản của bạn chưa được cộng tiền sau 10 phút, xin vui lòng liên hệ admin <a
                          href={'https://m.me/kuhau2103'} target={'_blank'} rel="noreferrer">&nbsp;tại đây. </a>
                      </Descriptions.Item>
                      <Descriptions.Item
                          contentStyle={{fontWeight: 'bold', color: 'red'}}
                          label={'Lưu ý'}
                      >
                        Gửi sai mệnh giá sẽ mất 100% thẻ
                      </Descriptions.Item>

                    </Descriptions>
                }/>
            </MessageContainer>,
            <Button type="primary" href={'/'} style={{margin: 8}} icon={<HomeFilled/>}>
              Quay về trang chủ
            </Button>,
          ]}
        >
        </Result>
      </Container>
    );
  }
}
