const bankNameQrMap = {
    'VTB': 'VietinBank',
    'VCB': 'Vietcombank',
    'ACB': 'ACB',
    'MB': 'MBBank',
    'VIB': 'VIB',
}

const generateUrl = (bankCode: string, accountNumber: string, amountVnd: number,
                     content: string, accountName?: string) => {
  // @ts-ignore
  const bankName = bankNameQrMap[bankCode]
  if (!bankName) {
    return ''
  }
  const genAccountName = accountName ? accountName : 'Nguyen Dang Hoang Phuc'
  return `https://img.vietqr.io/image/${bankName}-${encodeURIComponent(accountNumber)}-compact2.jpg?` +
    `amount=${encodeURIComponent(amountVnd.toString())}` +
    `&addInfo=${encodeURIComponent(content)}&accountName=${encodeURIComponent(genAccountName)}`
}

const generateMomoQr = (phoneNumber: string, accountName: string, amount: number, content: string) => {
  return `2|99|${phoneNumber}|${accountName}||0|0|${amount}|${content}`;
}

export const vietqr = {
  generateUrl,
  generateMomoQr,
}
