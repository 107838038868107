import React, {useCallback, useState} from 'react';
import {Alert, Button, Divider, Typography} from "antd";
import {PageHeader} from "@ant-design/pro-layout";
import styled from "styled-components";
import useHomeInfo from "../../features/auth/HomeInfoProvider";
import TwoModeRichText from "../../commons/components/TwoModeRichText";
import {saveSetting} from "../../services/admin";
import {EditOutlined} from '@ant-design/icons';
import useAuth from "../../features/auth/AuthProvider";

const {Link} = Typography;

const MessageContainer = styled(Alert)`
  margin-bottom: 18px;
`;
const HeaderLabel = styled(Typography)`
  font-weight: bold;
  color: blue;
`;
const SubHeaderLabel = styled(Typography)`
  font-weight: bold;
`;
const LinkLabel = styled(Link)`
  font-style: italic;
  font-weight: bold;
`;

interface IInfoPanelProps {
  forCard: boolean
}

export default function InfoPanel({forCard}: IInfoPanelProps) {
  const {homeInfo} = useHomeInfo();
  const {userInfo} = useAuth();
  const [editIntro, setEditIntro] = useState(false);

  const onSaveEditIntro = useCallback(async (content: string) => {
    await saveSetting({
      type: forCard ? "WEB_INTRO_CARD" : "WEB_INTRO",
      value: content,
    });
    setEditIntro(false);
  }, [forCard]);

  const onClickEditIntro = useCallback(() => {
    setEditIntro(true);
  }, []);

  return (
    <div>
      <MessageContainer
        message={(<TwoModeRichText
          editable={editIntro}
          onSave={onSaveEditIntro}
          content={forCard ? homeInfo.webIntroForCard : homeInfo.webIntro}
        />)}
        action={
          userInfo.role === "SUPER_ADMIN" && !editIntro ?
            <Button icon={<EditOutlined onClick={onClickEditIntro}/>}/> : undefined
        }
      />
      <MessageContainer
        message={
          <div>
            <PageHeader title={"Hướng dẫn"}/>
            <div>
              <HeaderLabel>#BÁN</HeaderLabel>
              <ul>
                <li>
                  <SubHeaderLabel>{forCard
                    ? 'Nhập thẻ cào điện thoại - coin tự động chuyển vào tài khoản csgoempire của bạn'
                    : 'Value chuyển khoản - coin tự động chuyển vào empire của bạn'}</SubHeaderLabel>
                  <ul>
                    <li>Rate x{homeInfo.csgoEmpireRate}</li>
                  </ul>
                </li>
                <li>
                  <SubHeaderLabel>
                    Value ra card: <LinkLabel target={'_blank'} href={'https://ezvalue.vn/user/depositCard'}>Click
                    here </LinkLabel>
                    <Typography style={{color: 'red', fontStyle: 'italic', display: 'inline-block'}}> (login trước khi
                      nạp)</Typography>
                  </SubHeaderLabel>
                </li>
              </ul>
              <HeaderLabel>#MUA</HeaderLabel>
              <ul>
                <li>
                  <SubHeaderLabel>Mua chuyển khoản: - inbox <LinkLabel href={'https://www.facebook.com/kuhau2103/'}
                                                                       target={'_blank'}>Facebook</LinkLabel> (1p có
                    tiền)</SubHeaderLabel>
                  <ul>
                    <li>
                      Items: x{homeInfo.buyingRate}
                    </li>
                  </ul>
                </li>
              </ul>
              <Divider/>
              <Typography style={{fontStyle: 'italic', color: 'blue'}}>(Lưu ý: Nếu delay không nhận được coin thì vui
                lòng ib steam ID kèm với bill chuyển khoản nhé).</Typography>
            </div>
          </div>
        }/>
    </div>
  )
}
