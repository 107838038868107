import {request} from "../utils/http";
import moment from "moment";

export interface IRequestTipInfo {
    steamId: string;
    amount: number;
    bank: string;
}

/** RequestTipByCardDto */
export interface IRequestCardTipInfo {
    telco: string,
    code: string,
    serial: string,
    value: number,
    steamId: string
}

export interface IRequestTipResult {
    tipId: number;
    depId: number;
}

export interface IDepRequestResult {
    id: number,
    amount: number,
    status: RequestStatus,
    bank: BankInfo,
    comment?: string,
    actualAmount: number,
    createdAt: moment.Moment
}

export interface ITipRequestResult {
    id: number,
    steamId: string,
    amount: number,
    status: RequestStatus,
    comment?: string,
    createdAt: moment.Moment
}

export function requestTip(requestTipInfo: IRequestTipInfo): Promise<IRequestTipResult> {
    return request<IRequestTipResult>({
        url: '/home/request-tip',
        method: 'POST',
        authRequired: false,
        data: requestTipInfo,
    });
}

export function requestTipByMobileCard(requestCardTipInfo: IRequestCardTipInfo): Promise<IRequestTipResult> {
    return request<IRequestTipResult>({
        url: '/home/request-card-tip',
        method: 'POST',
        authRequired: false,
        data: requestCardTipInfo,
    });
}

export function getTipRequestInfo(tipId: string | number): Promise<ITipRequestResult> {
    return request<ITipRequestResult>({
        url: '/home/tip-info',
        method: 'GET',
        authRequired: false,
        params: {
            tipId
        }
    });
}

export function getDepRequestInfo(depId: string | number): Promise<IDepRequestResult> {
    return request<IDepRequestResult>({
        url: '/home/dep-info',
        method: 'GET',
        authRequired: false,
        params: {
            depId
        }
    });
}

export function getHomeInfo(): Promise<HomeInfo> {
    return request<HomeInfo>({
        url: '/home/info',
        method: 'GET',
        authRequired: false,
    })
}

export function getTipVnd(tipValue: number): Promise<number> {
    return request({
        url: '/home/get-tip-vnd',
        method: 'GET',
        authRequired: false,
        params: {
            tipValue: tipValue
        }
    });
}