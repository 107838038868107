import {Card, Image} from "antd";
import React from "react";
import QRCode from "react-qr-code";

interface QrCodeImageProps {
    value: string,
    description?: string,
    isUrl: boolean
}

export const QrCodeImage = (props: QrCodeImageProps) => {
    const description = props.description ? props.description
        : 'Quét QR để chuyển khoản nhanh hơn, tự điền stk và nội dung để tránh nhầm lẫn'
    return (
        <Card
            cover={props.isUrl ? <Image src={props.value} style={{maxWidth: "300px"}} preview={true}/>
                : <QRCode
                    size={300}
                    value={props.value}
                />}
        >
            <Card.Meta description={description}/>
        </Card>
    );
}